import { AgGridModule } from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '@layout';
import { LetDirective } from '@ngrx/component';
import { AgoDatePipe } from './pipes/ago-date.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { SelectPipe } from './pipes/select.pipe';
import { SelectsPipe } from './pipes/selects.pipe';
import { SortPipe } from './pipes/sort.pipe';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const pipes = [JoinPipe, SortPipe, SelectPipe, SelectsPipe, AgoDatePipe];

@NgModule({
  imports: [CommonModule, RouterModule, LetDirective],
  declarations: [...pipes],
  exports: [
    CommonModule,
    RouterModule,
    LetDirective,
    LayoutModule,
    AgGridModule,
    MatIconModule,
    ReactiveFormsModule,
    ...pipes,
  ],
})
export class SharedModule {}
